<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader :title="id ? 'Update Admin User' : 'Create Admin User'" />
        <div>
          <md-button :to="{ name: 'User Management' }" class="md-primary">
            Back
          </md-button>
        </div>
      </div>

      <div>
        <form novalidate class="" @submit.prevent="validateUser">
          <md-field :class="getValidationClass('state')">
            <label for="user_type">User Type</label>
            <md-select v-model="form.user_type" name="user_type" id="user_type">
              <md-option value="" disabled>Select User Type</md-option>
              <md-option
                v-for="(type, index) in userTypes"
                :key="index"
                :value="type"
                >{{ type }}</md-option
              >
            </md-select>
          </md-field>

          <div class="row">
            <div class="col-md-6">
              <md-field :class="getValidationClass('first_name')">
                <label>First Name</label>
                <md-input v-model="form.first_name" required></md-input>
                <span class="md-error" v-if="!$v.form.first_name.required"
                  >First name is required</span
                >
              </md-field>
            </div>
            <div class="col-md-6">
              <md-field :class="getValidationClass('last_name')">
                <label>Last Name</label>
                <md-input v-model="form.last_name" required></md-input>
                <span class="md-error" v-if="!$v.form.last_name.required"
                  >Last name is required</span
                >
              </md-field>
            </div>
          </div>

          <md-field :class="getValidationClass('email')">
            <label>Email Address</label>
            <md-input type="email" v-model="form.email" required></md-input>
            <span class="md-error" v-if="!$v.form.email.required"
              >Email is required</span
            >
            <span class="md-error" v-if="!$v.form.email.email"
              >Invalid Email address</span
            >
          </md-field>

          <md-field :class="getValidationClass('phone')">
            <label>Phone Number</label>
            <md-input type="number" v-model="form.phone"></md-input>
          </md-field>

          <md-field :class="getValidationClass('gender')">
            <label for="gender">Gender</label>
            <md-select v-model="form.gender" name="gender" id="gender">
              <md-option value="" disabled>Select Gender</md-option>
              <md-option :value="'Male'">Male</md-option>
              <md-option :value="'Female'">Female</md-option>
            </md-select>
          </md-field>

          <md-field :class="getValidationClass('dob')">
            <label>Date of birth </label>
            <md-input type="date" v-model="form.dob"></md-input>
          </md-field>

          <md-field :class="getValidationClass('password')">
            <label>Password</label>
            <md-input
              type="password"
              v-model="form.password"
              required
            ></md-input>
            <span class="md-error" v-if="!$v.form.password.required"
              >Password is required</span
            >
          </md-field>

          <md-field :class="getValidationClass('password_confirmation')">
            <label>Confirm Password</label>
            <md-input
              type="password"
              v-model="form.password_confirmation"
              required
            ></md-input>
            <span
              class="md-error"
              v-if="!$v.form.password_confirmation.required"
              >Password Confirmation is required</span
            >
          </md-field>

          <!-- Section Handyman -->

          <section v-if="form.user_type !== 'User'">
            <md-field :class="getValidationClass('company_name')">
              <label>Business or Company Name</label>
              <md-input
                type="text"
                v-model="form.company_name"
                required
              ></md-input>
              <span class="md-error" v-if="!$v.form.company_name.required"
                >Business name is required</span
              >
            </md-field>

            <md-field
              v-if="form.user_type == 'Vendor'"
              :class="getValidationClass('service')"
            >
              <label for="services">Vendor Business Type</label>
              <md-select
                v-model="form.business_type"
                name="services"
                id="services"
              >
                <md-option value="" disabled>Select Business Type</md-option>
                <md-option value="Individual/Business">
                  Individual/Business
                </md-option>
                <md-option value="Entity/Company">Entity/Company</md-option>
              </md-select>
            </md-field>

            <md-field
              v-if="form.user_type == 'Realtor'"
              :class="getValidationClass('service')"
            >
              <label for="services">Business Type *</label>
              <md-select
                v-model="form.business_type"
                name="services"
                id="services"
              >
                <md-option value="" disabled>Select Business Type</md-option>
                <md-option value="real-estate-agent">
                  Real Estate Agent
                </md-option>
                <md-option value="property-developer"
                  >Property Developer</md-option
                >
              </md-select>
            </md-field>

            <div class="row">
              <div class="col-md-6">
                <md-field :class="getValidationClass('address ')">
                  <label>Company Address</label>
                  <md-input
                    type="text"
                    v-model="form.address"
                    required
                  ></md-input>
                  <span class="md-error" v-if="!$v.form.address.required"
                    >Company address is required</span
                  >
                </md-field>
              </div>
              <div class="col-md-6">
                <md-field :class="getValidationClass('state')">
                  <label for="state">State</label>
                  <md-select v-model="form.state_id" name="state" id="state">
                    <md-option value="" disabled>Select State</md-option>
                    <md-option
                      v-for="(state, index) in formData.states"
                      :key="index"
                      :value="index"
                      >{{ state }}</md-option
                    >
                  </md-select>
                </md-field>
              </div>
            </div>
            <div v-if="form.user_type !== 'Vendor'">
              <md-field :class="getValidationClass('about')">
                <label>Business Scope</label>
                <md-textarea v-model="form.about" required></md-textarea>
                <span class="md-error" v-if="!$v.form.about.required"
                  >Business Scope is required</span
                >
              </md-field>
            </div>

            <md-field
              v-if="!['Vendor', 'Realtor'].includes(form.user_type)"
              :class="getValidationClass('service')"
            >
              <label for="services">What services are you offering? </label>
              <md-select
                multiple
                v-model="form.services"
                name="services"
                id="services"
              >
                <md-option value="" disabled>Select Services</md-option>
                <md-option
                  v-for="(service, index) in services"
                  :key="index"
                  :value="service.id"
                  >{{ service.title }}</md-option
                >
              </md-select>
            </md-field>

            <md-field v-if="!['Vendor', 'Realtor'].includes(form.user_type)">
              <label>Logo Upload (optional)</label>
              <md-file
                type="file"
                accept="image/*"
                name="logo"
                @md-change="(e) => handleFile(e, 'logo')"
              />
            </md-field>

            <md-field>
              <label>Valid ID Card Upload (optional)</label>
              <md-file
                type="file"
                name="id_card"
                @md-change="(e) => handleFile(e, 'id_card')"
              />
            </md-field>

            <md-field
              v-if="
                ['Professionals', 'Vendor', 'Realtor'].includes(form.user_type)
              "
            >
              <label>CAC Reg. Certificate </label>
              <md-file
                v-model="form.cac_reg_upload"
                type="file"
                accept="image/*"
                name="cac_reg_upload"
                @md-change="handleFile('cac_reg_upload')"
              />
            </md-field>
          </section>

          <br />

          <mdc-button :loading="loading">{{
            id ? "Update User" : "Create User"
          }}</mdc-button>
        </form>
      </div>
    </md-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  email,
  requiredIf,
} from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("user");

export default {
  mixins: [validationMixin],
  data: () => ({
    form: {
      user_type: "User",
    },
    userTypes: ["User", "Handyman", "Professional", "Realtor", "Vendor"],
    loading: false,
    formData: {
      professional_services: [],
      handyman_services: [],
      states: [],
    },
  }),
  validations: {
    form: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
      },
      password: {
        required,
      },
      password_confirmation: {
        required,
      },
      company_name: {
        required: requiredIf(function () {
          return !["User"].includes(this.form.user_type);
        }),
      },
      address: {
        required: requiredIf(function () {
          return !["User"].includes(this.form.user_type);
        }),
      },
      state_id: {
        required: requiredIf(function () {
          return !["User"].includes(this.form.user_type);
        }),
      },
      about: {
        required: requiredIf(function () {
          return !["Vendor", "Realtor", "User"].includes(this.form.user_type);
        }),
      },
    },
  },
  methods: {
    ...mapActions(["createUser", "updateUser", "getUFormData"]),
    async validateUser() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let data = { ...this.form };
        data.phone_ext = "+234";
        data.user_id = this.user.id;
        data.is_admin = true;

        // alert(JSON.stringify(data));
        try {
          this.loading = true;
          let call = this.id ? this.updateUser(data) : this.createUser(data);
          let msg = this.id ? "User Updated" : "User Created";
          await call;
          this.toast("success", msg);
          this.$router.push({ name: "User Management" });
          this.loading = false;
        } catch (err) {
          console.log(err);
          this.loading = false;
          this.toast("error", "Opps, Something went wrong ");
        }
      } else {
        console.log(this.$v);
        alert("invalid Input");
      }
    },
    async getData() {
      const formData = await this.getUFormData();
      this.formData.professional_services = formData.professional_services;
      this.formData.handyman_services = formData.handyman_services;
      this.formData.states = formData.states;
    },
    handleFile(file, field) {
      alert();
      if (field == "logo") {
        this.form.logo = file[0];
      }
      if (field == "cac_reg_upload") {
        this.form.cac_reg_upload = file[0];
      }
      if (field == "id_card") {
        this.form.id_card = file[0];
      }
    },
  },
  mounted() {
    this.getData();
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"];
    },
    id() {
      return this.$route.params.id;
    },
    services() {
      return this.form.user_type == "Handyman"
        ? this.formData.handyman_services
        : this.formData.professional_services;
    },
  },
};
</script>

<style scoped lang="scss">
.feature-attributes {
  width: 100%;
  .wrapper {
    display: flex;
    align-items: center;
    gap: 20px;

    .val {
      flex: 1;
    }

    .attr {
      width: 25%;
    }
  }
}
</style>
